import aboutStyles from './about.module.scss'
import { Helmet } from "react-helmet"
import React, { useEffect, useState } from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'

export const query = graphql`
  {
    takiImg: file(relativePath:{eq: "taki.png"}){
      childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function About(props) {
  console.log(props)
  const [IsLoaded, setIsLoaded] = useState()
  useEffect(() => {
    return (function () {
      sessionStorage.setItem("aboutIsLoaded", "1")
    })
  }, [])
  return (
    <div id={aboutStyles.container}>
      <Helmet>
        <title>
          {props.lang === "en" ? "About me" : ""}
          {props.lang === "jp" ? "僕について" : ""}
        </title>
        <meta name="description" content="This is my personal timeline! Feel free to know more about me, though, only very superficially.."/>
      </Helmet>
      <div id={aboutStyles.card}>
        <div id={aboutStyles.headerContainer}>
          <div id={aboutStyles.headerContent}>
            <div id={aboutStyles.highlight}></div>
            <div id={aboutStyles.headerTitle}>
              <div id={aboutStyles.headerSpacing}>
                <h1 id={aboutStyles.header}>
                  {props.lang === "en" ? "Taki Tachibana" : ""}
                  {props.lang === "jp" ? "立花瀧": ""}
                </h1>
                <div id={aboutStyles.headerDivider}></div>
                <p id={aboutStyles.headerRight}>
                  {props.lang === "en" ? "hs grad. > 17" : ""}
                  {props.lang === "jp" ? "17歳の高卒。" : ""}
                </p>
              </div>
              <div id={aboutStyles.intro}>
                <p>
                  {props.lang === "en" ? "Art, Web, MBTI, Anime." : ""}
                  {props.lang === "jp" ? "趣味：お絵描き、ウェブ、MBTI、アニメ。" : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id={aboutStyles.content}>
          <div id={aboutStyles.pictureContainer} className={(IsLoaded === "1" ? "":aboutStyles.pictureContainerTrigger)}>
            <div id={aboutStyles.picture}>
              <Img fluid={props.data.takiImg.childImageSharp.fluid} />
            </div>
            <div id={aboutStyles.pictureDeco}></div>
          </div>
          <div id={aboutStyles.summary} className={(IsLoaded === "1" ? "":aboutStyles.summaryTrigger)}>
            <div id={aboutStyles.summaryParaContainer}>
              <p>
                {props.lang === "en" ? "a taciturn entity," : ""}
                {props.lang === "jp" ? "jp line 1" : ""}
              </p>
              <br/>
              <p>
                {props.lang === "en" ? "lost in the fleeting moment of solitude," : ""}
                {props.lang === "jp" ? "jp line 2" : ""}
              </p>
              <br/>
              <p>
                {props.lang === "en" ? "dwelling in the realm of visual arts -" : ""}
                {props.lang === "jp" ? "jp line 3" : ""}
              </p>
            </div>
          </div>
          <div id={aboutStyles.details}>
            <div id={aboutStyles.skills}>
              <p id={aboutStyles.skillsText}>
                {props.lang === "en" ? "Skills" : ""}
                {props.lang === "jp" ? "skillsJP" : ""}
              </p>
              <div id={aboutStyles.skillsTextDash}></div>
              <div id={aboutStyles.skillsParaContainer}>
                <div className={aboutStyles.skillsBarContainer}>
                  <p className={aboutStyles.skillsLabel}>
                    {props.lang === "en" ? "Frontend Web" : ""}
                    {props.lang === "jp" ? "フロント エンド " : ""}
                  </p>
                  <div className={aboutStyles.fillBar}>
                    <div className={aboutStyles.fillBarFiller + " " +aboutStyles.fillBar40 + " "+(IsLoaded === "1" ? aboutStyles.filledBar : "")}></div>
                  </div>
                </div>
                <div className={aboutStyles.skillsBarContainer}>
                  <p className={aboutStyles.skillsLabel}>
                    {props.lang === "en" ? "Art" : ""}
                    {props.lang === "jp" ? "描き" : ""}
                  </p>
                  <div className={aboutStyles.fillBar}>
                    <div className={aboutStyles.fillBarFiller + " " +aboutStyles.fillBar20 + " "+(IsLoaded === "1" ? aboutStyles.filledBar : "")}></div>
                  </div>
                </div>
                <div className={aboutStyles.skillsBarContainer}>
                  <p className={aboutStyles.skillsLabel}>
                    {props.lang === "en" ? "Reservedness" : ""}
                    {props.lang === "jp" ? "照れ屋さ" : ""}
                  </p>
                  <div className={aboutStyles.fillBar}>
                    <div className={aboutStyles.fillBarFiller + " " +aboutStyles.fillBar90 + " "+(IsLoaded === "1" ? aboutStyles.filledBar : "")}></div>
                  </div>
                </div>
                <div className={aboutStyles.skillsBarContainer}>
                  <p className={aboutStyles.skillsLabel}>
                    {props.lang === "en" ? "Puppy intimacy" : ""}
                    {props.lang === "jp" ? "jp line 3" : ""}
                  </p>
                  <div className={aboutStyles.fillBar}>
                    <div className={aboutStyles.fillBarFiller + " " +aboutStyles.fillBar80 + " "+(IsLoaded === "1" ? aboutStyles.filledBar : "")}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
